@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  --primary_color: #005556;
  --secondary_color: #005556;
}

body {
  font-family: "Roboto", sans-serif !important;
}
.bg-1 {
  background: var(--primary_color);
}
#hambergar:hover {
  background-color: transparent !important;
}
.sideNav-divider {
  background-color: #ededed;
  bottom: 0;
  height: 1px;
  left: 72px;
  position: absolute;
  right: 0;
}

#mySidebar a:nth-child(odd) {
  background: transparent;
}
.sideNav-arrow {
  position: absolute;
  right: 25px;
}

.profile_box {
  width: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
  background: #f6f8fa;
  margin-bottom: 15px;
  border: 1px solid #ddd;
}

.profile_img {
  display: flex;
}

.profile_pic {
  border-radius: 50px;
  margin-right: -15px;
  z-index: 1;
  position: relative;
}

.details {
  position: relative;
  border-radius: 15px;
  border: 1px solid #fff;
  background: #005556;
  padding: 3px 8px;
  margin-bottom: 10px;
  font-size: 16px;
  i {
    border-radius: 5px;
    padding: 5px;
  }
}

.battle {
  display: flex;
  justify-content: space-around;
}

.phone {
  background: #41a5ee;
}

.mail {
  background: #ff2147;
}

.kyc {
  background: #ff9100;
}

.bell {
  padding: 10px 5px;
}

.bell .fa-bell {
  border: 1px solid;
  border-radius: 50%;
  padding: 8px;
  font-size: 1.2rem;
  color: #fbc905;
}

.profile_box span {
  font-size: 13px;
  font-weight: 600;
}
.profile_box i {
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 5px;
}
.edit_profile {
  background: #fff;
  border-radius: 10px;
  padding: 5px 8px;
  cursor: pointer;
  span {
    font-size: 13px;
    font-weight: 700;
    margin-left: 8px;
  }
}

.wallet_box {
  .boxes {
    background: #c8c8c8;
    color: #fff;
    padding: 2px 10px;
    border-radius: 10px;
  }
}

.battle_info {
  display: flex;
  justify-content: center;
  width: 50%;
  .boxes {
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid #fff;
    min-height: 140px;
    text-align: center;
    margin-bottom: 15px;
    .undefined {
      color: #fff;
    }
  }
}

.log_out {
  background: #c70000;
  border-radius: 20px;
  padding: 5px 20px;
}

.bg-btn {
  background: var(--secondary_color);
}
.card {
  font-family: "Roboto", sans-serif;
}
.info {
  color: #978a05 !important;
}
.back_btn {
  .btn {
    padding: 10px 20px !important;
    background: var(--secondary_color);
    color: #fff !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    border-radius: 5px;
  }
}

.page-item {
  a.page-link {
    color: #000 !important;
  }
}

.bg-none {
  background: none;
}

.border_danger {
  border-color: #690202;
}

.t_history {
  background: #d5d5d5;
  border: 1px solid #b1b1b1;
  border-radius: 5px;
}
.sideNav-arrow > img {
  height: 11px;
  max-width: 100%;
  width: 6px;
}
.w3-button {
  /* background-color:blueviolet; */
  color: white;
  /* position:fixed */
  margin: auto;
  right: 0;
  top: 20px;
  /* right:/20px; */
  padding: 0.25rem;
  transition: transform 0.3s ease-in-out;
  /* background: linear-gradient(to right, #ff416c, #ff4b2b); */
}
.w3-button.active {
  transform: translateX(-100px);
}

.w3-sidebar {
  width: 65%;
  max-width: 300px;
  height: 100%;
  color: black !important;
  position: fixed;
  top: 0;
  left: -500px;
  height: 100vh;
  transition: all 0.5s;
  z-index: 99 !important;
  font-weight: 600;
}
.w3-container {
  padding: 0px;
  top: 0 !important;
  margin: auto;
  background: #fff !important;
}
.w3-teal {
  background: white !important;
}
.w3-bar-block .w3-bar-item {
  height: 50px;
  position: relative;
  width: 100%;
  align-items: center;
  display: flex;
  color: #000 !important;
  /* border-bottom: 1px solid; */
  margin-bottom: 10px;
  padding-left: 20px;
}

.far {
  font-size: 1.5rem;
}
.arrow {
  position: absolute;
  right: 25px;
}
.arrow > img {
  height: 20px;
  width: 6px;
  max-width: 100%;
}

.icon {
  overflow: hidden;
  position: relative;
}
.icon > img {
  height: 30px;
  width: 30px;
  /* max-width: 100%; */
}
#sidebarOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: none;
  transition: all 0.5s ease-in-out 0s;
}

.swal2-popup {
  background: #003441;
  color: #fff;
}
