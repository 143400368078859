:root {
  --primary_color: #6c0060;
}

.bg-1 {
  background: var(--primary_color);
}
.main-area {
  background-color: #ffffff;
  overflow: hidden;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.referbtn_refer {
  border: none;
  border-radius: 5px;
  font-size: 1em;
  font-weight: 700;
  height: 48px;
  padding: 0 22px;
  color: #fff;
  background: green !important;
  text-transform: uppercase;
}

.center_xy {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.progress {
  height: 1rem;
  line-height: 0;
  font-size: 0.75rem;
  background-color: rgb(233, 236, 239);
  border-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  display: flex;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
}

.progress_bar {
  display: flex;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
  flex-direction: column;
  justify-content: center;
  color: rgb(255, 255, 255);
  text-align: center;
  white-space: nowrap;
  background-color: #3a427d;
  transition: width 0.6s ease;
  transition-duration: 0.6s;
  transition-timing-function: ease;
  transition-delay: 0s;
  transition-property: width;
}

.progress_bar_striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 0px,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 0px,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 0px,
    transparent
  );
  background-size: 1rem 1rem;
}

.bg_success {
  background-color: rgb(40, 167, 69) !important;
}

.refer_footer {
  max-width: 480px;
  padding: 0 18px;
  width: 100%;
}

.font_11 {
  color: #2c2c2c;
  font-size: 1.1em;
  font-weight: 900;
}

.text_bold {
  color: #2c2c2c;
  font-size: 1em;
  font-weight: 800;
}
.font_9 {
  color: #2c2c2c !important;
  font-weight: 700 !important;
  font-size: 1em !important;
}
.font_8 {
  font-weight: 500 !important;
  font-size: 0.8em !important;
}
.c_green {
  color: #0db25b !important;
}


