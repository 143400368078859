:root {
  --primary_color: #005556;
  --bg_color: #005556;
  --white_color: #fff;
}

body {
  color: #fff;
}

.Home_betCard__9SvAl {
  -webkit-animation: linear;
  animation-delay: 0s;
  animation-direction: normal;
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  animation-fill-mode: none;
  animation-iteration-count: 1;
  -webkit-animation-name: Home_leftToRight__1z7tX;
  animation-name: Home_leftToRight__1z7tX;
  animation-play-state: running;
  animation-timing-function: linear;
  background: linear-gradient(0deg,#b9b9b9,#0000);
  border: 1px solid #c8c8c8;
  border-image-outset: 0;
  border-image-repeat: initial;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: relative;
}
.bg-1 {
  background: var(--primary_color);
}

.headerContainer,
.leftContainer {
  background-color: var(--white_color);
  max-width: 480px;
  width: 100%;
}

* {
  font-family: "Roboto";
}

*,
:after,
:before {
  box-sizing: border-box;
}

.leftContainer {
  min-height: 0%;
  position: relative;
}

.headerContainer {
  position: fixed;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 6%);
  height: 0px;
  padding: 0;
  top: 0;
  z-index: 2;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.navLogo>img {
  height: 36px;
  width: 36px;
}

.login-btn {
  border: 2px solid #3a427d;
  color: #000 !important;
  border-radius: 3px;
  font-size: 1.5em;
  font-weight: 700;
  height: 44px;
  padding: 4px 21px 20px;
  margin-right: 10px;
  background-color: #fff;
}

.rightContainer {
  background: url(../../../public/Images/global-bg-rays.jpg);
  background-color: #fff;
  background-size: cover;
  overflow: hidden;
  right: 0;
  z-index: 4;
}

.rightContainer {
  bottom: 0;
  left: 480px;
  position: fixed;
  top: 0;
  border-left: 10px solid #e0e0e0;
}

.open_battle {
  padding: 0;
}

.bid .open_battle:first-child {
  padding-top: 10px;
  padding-bottom: 2px;
  border-radius: 8px 8px 0 0;
}

.bid .open_battle:last-child {
  padding-top: 2px;
  padding-bottom: 15px;
  border-radius: 0 0 8px 8px;
  margin-bottom: 10px;
}

.rcBanner-footer>img {
  height: 25px;
  width: 25px;
  margin-top: 4px;
}

.rcBanner-img-container {
  bottom: 45%;
  position: absolute;
  width: 300px;
}

.rcBanner-img-container>img {
  height: auto;
  width: 220px;
}

.flex-center {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rcBanner-text {
  bottom: 35%;
  font-family: "Roboto Condensed";
  font-size: 2em;
  font-weight: 400;
  position: absolute;
  text-transform: uppercase;
}

.rcBanner-text-bold {
  font-weight: 900;
}

.main-area {
  padding-top: 10px;
  background-color: transparent;
  overflow: hidden;
  margin-top: 30px;
  width: 100%;
}

.setamt {
  border-radius: 5px;
}

.games-section {
  background: transparent;
  border-radius: 0;
  position: relative;
  width: 100%;
}

.p-3 {
  padding: 1rem !important;
}

.games-section-headline {
  color: #959595;
  font-size: 0.75em;
  font-weight: 400;
  line-height: 18px;
  background-color: transparent;
  position: relative;
  border-radius: 20px;
}

.popins {
  font-family: "Poppins", sans-serif;
}

.games-section-headline:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background: url(../../../public/Images/Homepage/dice.png);
  background-position: center;
  background-size: contain;
}

.Inner_profile {
  position: relative;
}

.games-window {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.gameCard-container {
  margin-top: 20px;
  justify-content: center;
  position: relative;
  width: 46.8%;
}

.bg_new {
  background: #f39e09;
}

.blink {
  animation: animate 2s linear infinite;
}

@keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.gameCard {
  background-color: #fff;
  border-radius: 5px;
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none;
}

.gameCard-image {
  border-radius: 5px;
  height: 70.33%;
  width: 100%;
}

.gameCard-image img {
  border-radius: 5px;
  filter: drop-shadow(2px 4px 6px black);
}

.gameCard-icon,
.gameCard-title {
  display: flex;
  justify-content: center;
}

.gameCard-title {
  border-radius: 0 0 5px 5px;
  border: 1px solid #e0e0e0;
  border-top: none;
  color: #2c2c2c;
  font-weight: 700;
  padding: 15px 20px 10px;
}

.games-section-title {
  color: #2c2c2c;
  font-size: 1em;
  font-weight: 100;
}

.rcBanner-footer {
  bottom: 40px;
  color: #2c2c2c;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 32px;
  position: absolute;
  text-align: center;
}

.footer {
  /* background-image: linear-gradient(rgb(61, 128, 197), rgb(37, 61, 118)) !important; */
  border-radius: 0 !important;
  position: relative !important;
  width: 100% !important;
  text-decoration: black;
  padding: 1% !important;
}

.footer-2 {
  background-image: linear-gradient(to right, #74e933, #e9d629);
}

.footer-divider {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.footer-links>a {
  color: #050505 !important;
  display: flex !important;
  font-size: 1.3em !important;
  font-weight: 400 !important;
  margin-bottom: 10px !important;
}

.footer-text-bold {
  color: #050505;
  font-size: 1em;
  font-weight: 700;
}

.footer-text {
  color: #050505;
  font-size: 0.9em;
  font-weight: 400;
}

a:hover {
  color: #0056b3 !important;
  text-decoration: underline;
}

a {
  color: #007bff !important;
  text-decoration: none;
  background-color: transparent;
}

.gameCard-container .goverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #008cba;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.collapseCard-container {
  padding: 30px 20px 20px;
  background-color: var(--primary_color);
}

.collapseCard {
  position: relative;
  width: 100%;
}

.collapseCard-body {
  padding-left: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  align-items: center;
  display: flex;
}

.collapseCard-header {
  background-color: #fafafa;
  padding-left: 3px;
  padding-right: 3px;
  top: -13px;
  align-items: center;
  position: absolute;
  display: flex;
  justify-content: center;
}

.collapseCard-title {
  color: #676767;
  font-size: 0.7em;
  font-weight: 700;
  text-transform: uppercase;
}

.alert-box {
  color: #21940a;
  background-color: #080006;
  border-color: #b8b265;
  border-radius: 0;
  padding: 9px 7px 2px 7px;
}

.header_top_message {
  background-color: #de2f4c;
  color: #fff !important;
  font-family: Poppins, sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 17px;
  max-width: 480px;
  padding: 8px;
  text-align: center;
  top: 10;
  width: 480px;
  width: 100%;
  z-index: 2;
}

.w1 {
  display: flex;
  justify-content: center;
  margin-bottom: 3px;
  position: relative;
}

.w2 {
  background-color: #03d25e;
  color: #fff;
  padding: 10px;
  border-radius: 30px;
  cursor: pointer;
  animation: 0s ease 0s infinite normal none running flash;
}

.w3 {
  font-weight: bold;
}

.whatsapp {
  padding: 15px;
  font-size: 35px;
  border-radius: 50%;
  display: inline-flex;
  position: absolute;
  top: -10px;
  box-shadow: inset 0 0 8px #000c02;
}

.support_samll_card_btn_type {
  align-items: center;
  background-color: #f6f8fa;
  border: 1px solid #c8c8c8 !important;
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100px;
  padding: 0.2rem 0.85rem !important;
}

.whatsap_btn,
.whatsap_btn1 {
  bottom: 30px;
  min-height: auto !important;
  position: fixed;
  z-index: 9999999999999;
}

.whatsap_btn {
  right: 20px;
}

.cash_btn {
  background: #005556;
  color: var(--white_color);
}

.Addcase_games_section_title__-FZq- {
  font-size: 1.2rem !important;
}

.addmoney {
  padding: 2px 5px 2px 0;
  border: 1px solid #fbc905;
}

.addmoney .fa-plus {
  color: darkred;
}

.addmoney .fa-gift {
  color: #fbc905;
  font-size: 32px;
}

.qr{
  text-align: center;
}
.qr img {
  width: 165px;
  max-width: 100%;
  border: 2px solid #fbc90557;
}

.qr_input{
  border: 1px solid #ccc !important;
   border-radius: 5px;
}

.qr p {
  font-weight: 700;
  font-size: 25px !important;
  font-family: sans-serif;
  margin: 10px auto 5px !important;
}

.all_policy li {
  padding: 10px;
  margin-top: 10px;
  background: #ebedf0;
  font-family: sans-serif;
  color: #010101;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.next_btn {
  background: var(--bg_color) !important;
}

.privacy p {
  font-family: sans-serif;
}

span.challanger {
  color: #d65317;
  font-weight: 700;
  font-size: 12px;
}

.refer_box {
  border: 1px solid #c8c8c8;
  border-radius: 8px;
  width: 100%;
}

.refer_code {
  border-radius: 5px 0 0 5px;
}

button.cash_btn {
  border-radius: 0 5px 5px 0;
}