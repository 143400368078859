body {
  font-family: "sans-serif";
}
.leftContainer.Login_page .main-area {
  align-items: center;
  background-color: initial;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
}

.Login_box {
  background-color: #fff;
  border: 1px solid #828282;
  border-radius: 5px;
  padding: 40px 25px;
  width: 90%;
}

.Login_box p {
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
}

.splash-overlay {
  background: linear-gradient(180deg, #00383b00 -500px, #005556 600.5px);
  background-size: cover !important;
  background-position: center !important;

  height: 100vh;
  pointer-events: none;
  position: absolute;
  width: 100%;
  max-width: 480px;
  z-index: 2;
}

.splash-screen {
  -webkit-animation: splashAnim 22s linear infinite;
  animation: splashAnim 22s linear infinite;
  height: 100%;
}

.center-xy {
  display: flex;
  flex-direction: column;
}

.login-footer {
  padding: 0 10px;
  color: #4f4f4f;
  padding-top: 100px;
  bottom: 0%;
  font-size: 0.75em;
  font-weight: 400;
  line-height: 15px;
  position: sticky;
  text-align: center;
  max-width: 440px;
  width: 100%;
}

element.style {
  top: 45%;
  z-index: 3;
}

.splash-screen {
  -webkit-animation: splashAnim 22s linear infinite;
  animation: splashAnim 22s linear infinite;
  height: 100%;
}

figure {
  margin: 0 0 1rem;
}

.font-15 {
  color: #2c2c2c;
  font-size: 1.5em;
  font-weight: 700;
}

.text-white {
  color: #fff !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.cxy,
.refer-button-copy {
  align-items: center;
  display: flex;
  justify-content: center;
}

element.style {
  width: 85%;
  height: 70px;
  border-radius: 5px;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

element.style {
  width: 100px;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.hidden {
  display: none !important;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.Login_box .input-group input {
  height: calc(2.25rem + 20px);
  border-radius: 5px;
  border-left: none;
}

.Login_box .input-group input:focus {
  border-color: #2c2c2c;
}

.input-group-prepend .input-group-text {
  border-radius: 5px;
  border-right: 0;
}

.cxy,
.refer-button-copy {
  align-items: center;
  display: flex;
  justify-content: center;
}

.refer-button {
  border: none;
  border-radius: 5px;
  font-size: 1em;
  font-weight: 700;
  height: 48px;
  padding: 0 22px;
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
}

.refer-button:hover {
  color: white !important;
  text-decoration: none;
}

.bg-green {
  background-color: #0db25b;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
input[name="mobile"] {
  border: 1px solid #6e6e6e;
}
input[name="mobile"]:focus {
  border-color: #003441;
}

.Login-button {
  border: none;
  border-radius: 5px;
  font-size: 1em;
  font-weight: 500;
  height: 48px;
  padding: 0 22px;
  color: #fff;
  background-color: #06a66e;
  width: 60%;
  font-weight: 900;
  text-transform: uppercase;
}
